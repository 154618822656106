import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';
import { useMemo } from 'react';

import { useAnalytics } from './AnalyticsContext';

class AuthAnalytics {
  constructor(private readonly analytics: AnalyticsBrowser) {}

  trackRegistrationFormSubmitted(props: EventProperties) {
    this.analytics.track('Registration Form Submitted', props);
  }

  trackEmailLoginFormSubmitted(props: EventProperties) {
    this.analytics.track('Email Login Form Submitted', props);
  }

  trackOAuthButtonClicked(props: EventProperties) {
    this.analytics.track('OAuth Button Clicked', props);
  }

  trackOAuthCallback(props: EventProperties) {
    this.analytics.track('OAuth Callback', props);
  }

  trackFailedLoginAttempt(props: EventProperties) {
    this.analytics.track('Failed Login Attempt', props);
  }

  trackRegistrationFailed(props: EventProperties) {
    this.analytics.track('Registration Failed', props);
  }
}

export function useAuthAnalytics(): AuthAnalytics {
  const analytics = useAnalytics();

  return useMemo(() => new AuthAnalytics(analytics), [analytics]);
}
